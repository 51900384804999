import { template as template_0b2389c1ddeb4201b9fc707a14051c1f } from "@ember/template-compiler";
const FKControlMenuContainer = template_0b2389c1ddeb4201b9fc707a14051c1f(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
