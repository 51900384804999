import { template as template_514f99df57e64878b9dff136847cbdac } from "@ember/template-compiler";
const WelcomeHeader = template_514f99df57e64878b9dff136847cbdac(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
