import { template as template_80bed2d0ae1149f38af3d3ac0ac8eef2 } from "@ember/template-compiler";
const SidebarSectionMessage = template_80bed2d0ae1149f38af3d3ac0ac8eef2(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
